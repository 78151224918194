.navbar{
    background-color: hsla(90, 17%, 93%, 0.849) !important;
}

li{
    font-family: 'Cabin' !important;
    font-size: 1.1rem;
    padding-left: 0.6vh;
    padding-right: 0.6vh;
}


.navbar-user{
    background-color: rgba(0, 0, 0, 0.329) !important;
}

li a{
    color: rgba(0, 0, 0, 0.844) !important;
}

a:hover.selection-word{
    color:#28a745 !important
}

.color-selected{
    color:#28a745 !important
}
